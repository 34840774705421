import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import FormatUtils from '../common/formatUtils/FormatUtils';

export function setAguardando(aguardando) {
    return {
        type: 'ETIQUETA_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function gerar(registro) {
    return (dispatch, getState) => {
		
		dispatch(setAguardando(true));

	    axios.post(`${consts.API_URL}/etiqueta`, {
			...registro,
			numero_caixa_inicio: FormatUtils.formatarValorBanco(registro.numero_caixa_inicio, 0),
			numero_caixa_fim: FormatUtils.formatarValorBanco(registro.numero_caixa_fim, 0),
			numero_caixa_total: FormatUtils.formatarValorBanco(registro.numero_caixa_total, 0)
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			
			window.open(`${consts.URL}/${resp.data}`, '_blank');

			dispatch(setAguardando(false));
		}).catch(e => {
			dispatch(setAguardando(false));
			setErroAPI(e);
		});
    };
}

const INITIAL_STATE = {
	registro: {},
	aguardando: false,
	lista: [],
	listaEnderecoDestino: [
		{ id: 'GRU5', valor: 'GRU5', descricao: 'Amazon CD: GRU5\nAmazon Servicos de Varejo do Brasil Ltda\nCNPJ: 15436940000367\nIE: 241099810118\nCondomínio Prologis 3: Av. Antonio Candido\nMachado 3100 Andar 5, 07776415 - Cajamar' },
		{ id: 'XBRA', valor: 'XBRA', descricao: 'Amazon CD: XBRA\nAmazon Servicos de Varejo do Brasil Ltda\nCNPJ: 15436940000448\nIE: 241118709114\nCondomínio Prologis 2: Av. Dr. João Abdalla 260\n07776700 - Cajamar - Bloco 400' },
		{ id: 'XBRB', valor: 'XBRB', descricao: 'Amazon CD: XBRB\nAmazon Servicos de Varejo do Brasil Ltda\nCNPJ: 15436940000529\nIE: 0842859-07\nRodovia BR-101 Sul, nº 3791Bloco C, Galpão 5, Sala A\n54.503-010 - Cabo de Santo Agostinho - PE' },
		{ id: 'XBRT', valor: 'XBRT', descricao: 'Amazon CD: XBRT\nAmazon Servicos de Varejo do Brasil Ltda\nCNPJ: 15436940000871\nIE: 241134643114\nVia de AcessoNorte KM 38, 420 –Mezanino do Galpão 07\nGleba A Bloco 07 CEP 07789-100 Cajamar – São Paulo' },
		{ id: 'XBRZ', valor: 'XBRZ', descricao: 'Amazon CD: XBRZ\nAmazon Servicos de Varejo do Brasil Ltda\nCNPJ: 15436940000286\nIE: 241115791117\nAvenida Doutor Antonio Joao Abadalla, 260 -Galpão 300\nCondomínio Prologis 2 CEP 07776-700 Cajamar, São Paulo' },
		{ id: 'CNF1', valor: 'CNF1', descricao: 'Amazon CD: CNF1\nAmazon Servicos de Varejo do Brasil Ltda\nCNPJ: 15436940000952\nIE: 002668590054\nAvenida Juiz Marco Tulio Isaac #7000\nCEP 32670 - 250, Betim - Minas Gerais' },
		{ id: 'BSB1', valor: 'BSB1', descricao: 'Amazon CD: BSB1\nAmazon Servicos de Varejo do Brasil Ltda\nCNPJ: 15436940001096\nIE: 0774927400365\nRodovia DF, #290, KM 1.2 Lote 13,14,15, 16 e 17 -\nCEP 72501 - 100, Fazenda Santa Maria Brasilia - Distrito Federal' },
		{ id: 'POA1', valor: 'POA1', descricao: 'Amazon CD: POA1\nAmazon Servicos de Varejo do Brasil Ltda\nCNPJ: 15436940001177\nIE: 3820025709\nRua da Pedreira, # 64, Pavilhão 05\nCEP 92480 - 000, Nova Santa Rita - Rio Grande do Sul' },
		{ id: 'GIG1', valor: 'GIG1', descricao: 'Amazon CD: GIG1\nAmazon Servicos de Varejo do Brasil Ltda\nCNPJ: 15436940001410\nIE: 11821987\nAv Arthur Antonio Sendas, S/N Area 6A\nCEP 25.585-021 , Sao Joao De Meriti, RJ Parque Analandia' },
		{ id: 'REC1', valor: 'REC1', descricao: 'Amazon CD: REC1\nAmazon Servicos de Varejo do Brasil Ltda\nCNPJ: 1543694000150\nIE: 0920380-00\nRodovia BR 101 SUL, nº 9415, galpão B.\nCEP 54503-010, Distrito Industrial Santo Estevão, Cabo de Santo' }
	]
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'ETIQUETA_SET_AGUARDANDO':
            return { ...state,
				aguardando: action.payload
			};

        case 'ETIQUETA_LISTADO':
            return {
				...state,
				lista: action.payload.data
			};

        default:
            return state;
    }
}

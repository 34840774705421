import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import DateFormat from '../common/dateFormat/DateFormat';
import Aguardando from '../common/template/aguardando';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import InputPesquisa from '../common/form/inputPesquisa';
import ReactExport from 'react-export-excel-fixed-xlsx';
import LaudoControleItem from '../laudoControleItem/laudoControleItem';
import LaudoOcorrencia from '../laudoOcorrencia/laudoOcorrencia';
import { MapLeaflet } from "../common/mapLeaflet/MapLeaflet";

import { 
	setFiltro, getLista, getListaTipo, getListaUnidade, getListaVeiculo, getListaEvento, baixarAnexos
} from './laudoControleActions';
import { 
	setModoTela as setModoTelaItem
} from '../laudoControleItem/laudoControleItemActions';
import { 
	setModoTela as setModoTelaOcorrencia
} from '../laudoOcorrencia/laudoOcorrenciaActions';
import FormatUtils from '../common/formatUtils/FormatUtils';

class LaudoControle extends Component {

	state = {
		pesquisar: '',
		linhaSelecionada: null,
		filtro: {
			classificada: false,
			vencido: false,
			ativo: true,
			inativo: false,
			encostado: false,
			alertaRevisaoTecnica: false,
			frota: 'normal'
		},
		ordenacao: {
			cavalo: 1,
			carreta1: 0,
			carreta2: 0,
			rumo: 0,
			nacional_internacional: 0,
			classificada: 0,
			data_vencimento_revisao_tecnica: 0,
			data_vencimento_ruido: 0,
			data_vencimento_opacidade: 0,
			data_vencimento_tacografo: 0,
			data_vencimento_revisao_tecnica_carreta1: 0,
			data_vencimento_revisao_tecnica_carreta2: 0,
			data_vencimento_revisao_carreta: 0,
			ocorrencia: 0,
			nome_fronteira: 0,
			rota: 0,
			veiculo_referencia: 0,
			nome_status: 0
		},
		selecao_todas: true,
		selecao_lista: []
	}

    componentWillMount() {
		this.props.getLista();
		this.props.getListaEvento();
		this.props.getListaTipo();
		this.props.getListaUnidade();
		this.props.getListaVeiculo();		
    }

	getNovaOrdenacao(atual) {
		return atual == 2 ? 0 : (atual || 0) + 1;
	}

	getOrdenacao(ordenacao) {
		return ordenacao == 1 
			? <i class="fas fa-sort-amount-down-alt" /> 
			: (
				ordenacao == 2 
					? <i class="fas fa-sort-amount-up" /> 
					: null
			);
	}

	filtrar() {
		
		let lista = this.props.lista.map(item => {

			//Busca o vencimento da revisão técnica mais recente
			let dataMenorVencimento = null;
			if (item.data_vencimento_revisao_tecnica) {
				dataMenorVencimento = item.data_vencimento_revisao_tecnica;
			}
			if (item.laudoControleCarreta1 && item.laudoControleCarreta1.data_vencimento_revisao_tecnica
				&& (!dataMenorVencimento || item.laudoControleCarreta1.data_vencimento_revisao_tecnica < dataMenorVencimento)) {
				dataMenorVencimento = item.laudoControleCarreta1.data_vencimento_revisao_tecnica;
			}
			if (item.laudoControleCarreta2 && item.laudoControleCarreta2.data_vencimento_revisao_tecnica
				&& (!dataMenorVencimento || item.laudoControleCarreta2.data_vencimento_revisao_tecnica < dataMenorVencimento)) {
				dataMenorVencimento = item.laudoControleCarreta2.data_vencimento_revisao_tecnica;
			}

			/*
			Se está vencido
			Se o veículo está com destino a uma cidade internacional e sua revisão técnica vence em menos de 60 dias.
			Veículo com destino Nacional ou sem destino dentro do Brasil e sua revisão técnica vence em menos de 30 dias.
			Veículo sem destino e está em solo internacional com sua revisão técnica a menos de 60 dias de vencer.
			*/
			let alertaVencimento = false;
			if (dataMenorVencimento) {				
				let diasVencimento = Math.ceil((new Date(dataMenorVencimento).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)); 
				if (diasVencimento <= 0) {
					alertaVencimento = true;
				} else if (diasVencimento <= 30) {
					alertaVencimento = true;
				} else if (item.destino_internacional && diasVencimento <= 60) {
					alertaVencimento = true;
				} else if (item.esta_internacional && diasVencimento <= 60) {
					alertaVencimento = true;
				}
			}
			//Se tem destino ou está em solo internacional e não possui revisão técnica
			if (
				(item.destino_internacional || item.esta_internacional)
				&& (
					(item.laudoControleCarreta1 && !item.laudoControleCarreta1.data_vencimento_revisao_tecnica)
					|| (item.laudoControleCarreta2 && !item.laudoControleCarreta2.data_vencimento_revisao_tecnica)
				)
			) {
				alertaVencimento = true;
			}

			return {
				...item,
				alerta_vencimento: alertaVencimento
			};
		}).filter(item => {
			return !(this.state.pesquisar || '').trim()
				|| (this.state.pesquisar.toUpperCase() || '').split('+').filter(pesquisar => (pesquisar || '').trim()).filter(pesquisar => {		
				if (pesquisar
					&& !(`
						${item.cavalo || ''}
						${item.carreta1 || ''}
						${item.carreta2 || ''}
						${item.rumo || ''}
						${item.rumo == 'NAC' ? 'NAC' : (item.rumo == 'RN' || item.rumo == 'RS' ? 'INT' : '-')}
						${DateFormat.formatarDataSqlParaTela(item.modo == 'cavalo' ? item.data_vencimento_revisao_tecnica : '')}
						${DateFormat.formatarDataSqlParaTela(item.modo == 'cavalo' ? item.data_vencimento_ruido : '')}
						${DateFormat.formatarDataSqlParaTela(item.modo == 'cavalo' ? item.data_vencimento_opacidade : '')}
						// ${DateFormat.formatarDataSqlParaTela(item.modo == 'cavalo' ? item.data_vencimento_tacografo : '')}
						${DateFormat.formatarDataSqlParaTela(item.laudoControleCarreta1 ? item.laudoControleCarreta1.data_vencimento_revisao_tecnica : null)}
						${DateFormat.formatarDataSqlParaTela(item.laudoControleCarreta2 ? item.laudoControleCarreta2.data_vencimento_revisao_tecnica : null)}
						${item.modo == 'cavalo' ? item.ocorrencia : (item.laudoControleCarreta1 ? item.laudoControleCarreta1.ocorrencia : '')}
						${item.nome_fronteira || ''}
						${item.rota || ''}
						${item.veiculo_referencia || ''}
						${item.nome_status || ''}
				`).toUpperCase().includes(pesquisar)) {
					return false;
				}	
				return true;
			}).length > 0;
		}).filter(item => {
			
			if (this.state.filtro.classificada && !item.classificada) {
				return false;
			}
			if (this.state.filtro.vencido 
				&& !((item.modo == 'cavalo' && item.vencido_revisao_tecnica) 
					|| (item.modo == 'cavalo' && item.vencido_ruido)
					|| (item.modo == 'cavalo' && item.vencido_opacidade)
					|| (item.modo == 'cavalo' && item.vencido_tacografo)
					|| (item.laudoControleCarreta1 && item.laudoControleCarreta1.vencido_revisao_tecnica)
					|| (item.laudoControleCarreta2 && item.laudoControleCarreta2.vencido_revisao_tecnica))) {
				return false;
			}
			if (this.state.filtro.frota == 'normal' && item.bitrem) { //Ativo
				return false;
			}
			if (this.state.filtro.frota == 'bitrem' && !item.bitrem) { //Ativo
				return false;
			}
			if (!this.state.filtro.ativo && item.id_status == 1) { //Ativo
				return false;
			}
			if (!this.state.filtro.inativo && item.id_status == 0) { //Inativo
				return false;
			}
			if ((!this.state.filtro.encostado && (item.id_status == 3 || item.id_status == 2 || item.id_status == 4))
				|| (this.state.filtro.encostado && (item.id_status != 3 && item.id_status != 2 && item.id_status != 4))) { //Encostado, Acidentado ou Manutenção
				return false;
			}
			if (this.state.filtro.alertaRevisaoTecnica && !item.alerta_vencimento) { 
				return false;
			}

			return true;
		});

		if (this.state.ordenacao.cavalo) {
			lista.sort((a, b) => this.state.ordenacao.cavalo == 1 
				? (a.cavalo > b.cavalo ? 1 : -1) 
				: (a.cavalo > b.cavalo ? -1 : 1));		
		} else if (this.state.ordenacao.carreta1) {
			lista.sort((a, b) => this.state.ordenacao.carreta1 == 1 
				? ((a.carreta1 || '') > (b.carreta1 || '') ? 1 : -1) 
				: ((a.carreta1 || '') > (b.carreta1 || '') ? -1 : 1));
		} else if (this.state.ordenacao.carreta2) {
			lista.sort((a, b) => this.state.ordenacao.carreta2 == 1 
				? ((a.carreta2 || '') > (b.carreta2 || '') ? 1 : -1) 
				: ((a.carreta2 || '') > (b.carreta2 || '') ? -1 : 1));
		} else if (this.state.ordenacao.rumo) {
			lista.sort((a, b) => this.state.ordenacao.rumo == 1 
				? ((a.rumo || '') > (b.rumo || '') ? 1 : -1) 
				: ((a.rumo || '') > (b.rumo || '') ? -1 : 1));
		} else if (this.state.ordenacao.nacional_internacional) {
			lista.sort((a, b) => this.state.ordenacao.nacional_internacional == 1 
				? ((a.rumo == 'NAC' ? 'NAC' : (a.rumo == 'RN' || a.rumo == 'RS' ? 'INT' : '-')) > (b.rumo == 'NAC' ? 'NAC' : (b.rumo == 'RN' || b.rumo == 'RS' ? 'INT' : '-')) ? 1 : -1) 
				: ((a.rumo == 'NAC' ? 'NAC' : (a.rumo == 'RN' || a.rumo == 'RS' ? 'INT' : '-')) > (b.rumo == 'NAC' ? 'NAC' : (b.rumo == 'RN' || b.rumo == 'RS' ? 'INT' : '-')) ? -1 : 1));
		} else if (this.state.ordenacao.classificada) {
			lista.sort((a, b) => this.state.ordenacao.classificada == 1 
				? (a.classificada > b.classificada ? 1 : -1) 
				: (a.classificada > b.classificada ? -1 : 1));
		} else if (this.state.ordenacao.data_vencimento_revisao_tecnica) {
			lista.sort((a, b) => this.state.ordenacao.data_vencimento_revisao_tecnica == 1 
				? ((a.modo == 'cavalo' ? a.data_vencimento_revisao_tecnica || '' : '') > (b.modo == 'cavalo' ? b.data_vencimento_revisao_tecnica || '' : '') ? 1 : -1) 
				: ((a.modo == 'cavalo' ? a.data_vencimento_revisao_tecnica || '' : '') > (b.modo == 'cavalo' ? b.data_vencimento_revisao_tecnica || '' : '') ? -1 : 1));
		} else if (this.state.ordenacao.data_vencimento_ruido) {
			lista.sort((a, b) => this.state.ordenacao.data_vencimento_ruido == 1 
				? ((a.modo == 'cavalo' ? a.data_vencimento_ruido || '' : '') > (b.modo == 'cavalo' ? b.data_vencimento_ruido || '' : '') ? 1 : -1) 
				: ((a.modo == 'cavalo' ? a.data_vencimento_ruido || '' : '') > (b.modo == 'cavalo' ? b.data_vencimento_ruido || '' : '') ? -1 : 1));
		} else if (this.state.ordenacao.data_vencimento_opacidade) {
			lista.sort((a, b) => this.state.ordenacao.data_vencimento_opacidade == 1 
				? ((a.modo == 'cavalo' ? a.data_vencimento_opacidade || '' : '') > (b.modo == 'cavalo' ? b.data_vencimento_opacidade || '' : '') ? 1 : -1) 
				: ((a.modo == 'cavalo' ? a.data_vencimento_opacidade || '' : '') > (b.modo == 'cavalo' ? b.data_vencimento_opacidade || '' : '') ? -1 : 1));
		} else if (this.state.ordenacao.data_vencimento_tacografo) {
			lista.sort((a, b) => this.state.ordenacao.data_vencimento_tacografo == 1 
				? ((a.modo == 'cavalo' ? a.data_vencimento_tacografo || '' : '') > (b.modo == 'cavalo' ? b.data_vencimento_tacografo || '' : '') ? 1 : -1) 
				: ((a.modo == 'cavalo' ? a.data_vencimento_tacografo || '' : '') > (b.modo == 'cavalo' ? b.data_vencimento_tacografo || '' : '') ? -1 : 1));
		} else if (this.state.ordenacao.data_vencimento_revisao_tecnica_carreta1) {
			lista.sort((a, b) => this.state.ordenacao.data_vencimento_revisao_tecnica_carreta1 == 1 
				? ((a.laudoControleCarreta1 ? a.laudoControleCarreta1.data_vencimento_revisao_tecnica || '' : '') > (b.laudoControleCarreta1 ? b.laudoControleCarreta1.data_vencimento_revisao_tecnica || '' : '') ? 1 : -1) 
				: ((a.laudoControleCarreta1 ? a.laudoControleCarreta1.data_vencimento_revisao_tecnica || '' : '') > (b.laudoControleCarreta1 ? b.laudoControleCarreta1.data_vencimento_revisao_tecnica || '' : '') ? -1 : 1));
		} else if (this.state.ordenacao.data_vencimento_revisao_tecnica_carreta2) {
			lista.sort((a, b) => this.state.ordenacao.data_vencimento_revisao_tecnica_carreta2 == 1 
				? ((a.laudoControleCarreta2 ? a.laudoControleCarreta2.data_vencimento_revisao_tecnica || '' : '') > (b.laudoControleCarreta2 ? b.laudoControleCarreta2.data_vencimento_revisao_tecnica || '' : '') ? 1 : -1) 
				: ((a.laudoControleCarreta2 ? a.laudoControleCarreta2.data_vencimento_revisao_tecnica || '' : '') > (b.laudoControleCarreta2 ? b.laudoControleCarreta2.data_vencimento_revisao_tecnica || '' : '') ? -1 : 1));
		} else if (this.state.ordenacao.ocorrencia) {
			lista.sort((a, b) => this.state.ordenacao.ocorrencia == 1 
				? ((a.modo == 'cavalo' ? a.ocorrencia || '' : (a.laudoControleCarreta1 ? a.laudoControleCarreta1.ocorrencia || '' : '')) > (b.modo == 'cavalo' ? a.ocorrencia || '' : (b.laudoControleCarreta1 ? b.laudoControleCarreta1.ocorrencia || '' : '')) ? 1 : -1) 
				: ((a.modo == 'cavalo' ? a.ocorrencia || '' : (a.laudoControleCarreta1 ? a.laudoControleCarreta1.ocorrencia || '' : '')) > (b.modo == 'cavalo' ? a.ocorrencia || '' : (b.laudoControleCarreta1 ? b.laudoControleCarreta1.ocorrencia || '' : '')) ? -1 : 1));

				
		} else if (this.state.ordenacao.nome_fronteira) {
			lista.sort((a, b) => this.state.ordenacao.nome_fronteira == 1 
				? ((a.nome_fronteira || '') > (b.nome_fronteira || '') ? 1 : -1) 
				: ((a.nome_fronteira || '') > (b.nome_fronteira || '') ? -1 : 1));
		} else if (this.state.ordenacao.rota) {
			lista.sort((a, b) => this.state.ordenacao.rota == 1 
				? ((a.rota || '') > (b.rota || '') ? 1 : -1) 
				: ((a.rota || '') > (b.rota || '') ? -1 : 1));
		} else if (this.state.ordenacao.veiculo_referencia) {
			lista.sort((a, b) => this.state.ordenacao.veiculo_referencia == 1 
				? ((a.veiculo_referencia || '') > (b.veiculo_referencia || '') ? 1 : -1) 
				: ((a.veiculo_referencia || '') > (b.veiculo_referencia || '') ? -1 : 1));
		} else if (this.state.ordenacao.nome_status) {
			lista.sort((a, b) => this.state.ordenacao.nome_status == 1 
				? ((a.nome_status || '') > (b.nome_status || '') ? 1 : -1) 
				: ((a.nome_status || '') > (b.nome_status || '') ? -1 : 1));
		}

		return lista;

	}

	montarExcel(lista) {

		const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
		const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

		return (
			<ExcelSheet data={lista.map(item => ({
				...item,
				data_vencimento_revisao_tecnica: DateFormat.formatarDataSqlParaTela(item.modo == 'cavalo' ? item.data_vencimento_revisao_tecnica : ''),
				data_vencimento_ruido: DateFormat.formatarDataSqlParaTela(item.modo == 'cavalo' ? item.data_vencimento_ruido : ''),
				data_vencimento_opacidade: DateFormat.formatarDataSqlParaTela(item.modo == 'cavalo' ? item.data_vencimento_opacidade : ''),
				data_vencimento_tacografo: DateFormat.formatarDataSqlParaTela(item.modo == 'cavalo' ? item.data_vencimento_tacografo : ''),
				data_vencimento_revisao_tecnica_carreta1: DateFormat.formatarDataSqlParaTela(item.laudoControleCarreta1 ? item.laudoControleCarreta1.data_vencimento_revisao_tecnica : null),
				data_vencimento_revisao_tecnica_carreta2: DateFormat.formatarDataSqlParaTela(item.laudoControleCarreta2 ? item.laudoControleCarreta2.data_vencimento_revisao_tecnica : null),
				nacional_internacional: item.rumo == 'NAC' ? 'NAC' : (item.rumo == 'RN' || item.rumo == 'RS' ? 'INT' : '-'),
				classificada: item.classificada ? 'SIM' : 'NÃO'
			})).filter(item => {
				return this.state.selecao_todas || this.state.selecao_lista.filter(selecao => selecao == item.id)[0];
			})} name='Laudos'>
				{[	
					{ componente: <ExcelColumn key={1} label='Cavalo' value='cavalo' />},
					{ componente: <ExcelColumn key={2} label='Carreta 1' value='carreta1' />},
					{ componente: <ExcelColumn key={2} label='Carreta 2' value='carreta2' />},
					{ componente: <ExcelColumn key={3} label='Rumo' value='rumo' />},
					{ componente: <ExcelColumn key={4} label='Nac/Int' value='nacional_internacional' />},
					{ componente: <ExcelColumn key={4} label='Classificada' value='classificada' />},
					{ componente: <ExcelColumn key={5} label='Rev.Técnica' value='data_vencimento_revisao_tecnica' />},
					{ componente: <ExcelColumn key={6} label='Ruído' value='data_vencimento_ruido' />},
					{ componente: <ExcelColumn key={7} label='Opacidade' value='data_vencimento_opacidade' />},
					// { componente: <ExcelColumn key={8} label='Tacógrafo' value='data_vencimento_tacografo' />},
					{ componente: <ExcelColumn key={9} label='Rev.Carreta 1' value='data_vencimento_revisao_tecnica_carreta1' />},
					{ componente: <ExcelColumn key={10} label='Rev.Carreta 2' value='data_vencimento_revisao_tecnica_carreta2' />},
					{ componente: <ExcelColumn key={11} label='Ocorrência' value='ocorrencia' />},
					{ componente: <ExcelColumn key={12} label='Fronteira' value='nome_fronteira' />},
					{ componente: <ExcelColumn key={13} label='Percurso' value='rota' />},
					{ componente: <ExcelColumn key={14} label='Localização' value='veiculo_referencia' />},
					{ componente: <ExcelColumn key={15} label='Status' value='nome_status' />}
				].filter(item => {
					return true;
				}).map(item => (
					item.componente
				))}
			</ExcelSheet>
		);
	}

	montarCard(nome, engatado, desengatado, atrasado, ocorrencia) {

		let total = engatado + desengatado;

		let percentual = total > 0 ? ((total - atrasado) * 100) / total : 0;

		return (
			<div class="col-xs-6 col-sm-6 col-md-6 col-lg-3" 
				style={{ cursor: 'pointer' }}
				onClick={() => {}}>
				<div class="small-box"
					style={{
						backgroundColor: 'rgb(31, 48, 102, 0.9)'
					}}>
					<div class="inner">
						<h5 style={{ textAlign: 'center', color: '#ffffff', fontSize: 16 }}>{nome}</h5>
						<div class="progress-group">
							<div style={{ backgroundColor: '#97A1BF', padding: 1}}>
								<div class="progress progress-sm" style={{ backgroundColor: total > 0 ? '#ed0000' : '#f2f2f2' }}>
									<div class={`progress-bar bg-success`} style={{ width: `${percentual}%` }}></div>
								</div>
							</div>
						</div>
						<div style={{ display: 'flex', justifyContent: 'center' }}>
							<div style={{ fontWeight: 'bold', color: '#ffffff' }} title={'Engatados'}>
								<i class="fas fa-truck-moving" style={{ marginRight: 2 }}></i>{engatado}
							</div>
							<div style={{ fontWeight: 'bold', marginLeft: 8, color: '#ffffff' }} title={'Desengatados'}>
								<i class="fas fa-truck-loading" style={{ marginRight: 2 }}></i>{desengatado}
							</div>
							<div style={{ fontWeight: 'bold', marginLeft: 8, marginRight: 8, color: '#ffffff' }} title={'Atrasados'}>
								<i class="fas fa-calendar-times" style={{ marginRight: 2 }}></i>{atrasado}
							</div>
							<div style={{ fontWeight: 'bold', color: '#ffffff' }} title={'Ocorrências'}>
								<i class="fas fa-bell" style={{ marginRight: 2 }}></i>{ocorrencia}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

    render() {

		const ExcelFile = ReactExport.ExcelFile;

		let percentual = 0;		
		
		return (
			<>	
				<Content>

					<Aguardando aguardando={this.props.aguardando} />

					{this.modalCavalo()}
					
					{this.modalItem()}

					{this.modalOcorrencia()}

					<Row alignCenter style={{ marginTop: 8 }}>

						{[1, 2, 3/*, 4*/].map(tipo => {

							let lista = this.props.lista.filter(item => {
								if (tipo == 1) {
									return item.data_vencimento_revisao_tecnica || (item.laudoControleCarreta1 && item.laudoControleCarreta1.data_vencimento_revisao_tecnica);
								} else if (tipo == 2) {
									return item.data_vencimento_ruido;
								} else if (tipo == 3) {
									return item.data_vencimento_opacidade;
								} else if (tipo == 4) {
									return item.data_vencimento_tacografo;
								}
							});

							let nome = ['Revisão Técnica', 'Ruído', 'Opacidade', 'Tacógrafo'][tipo-1];

							return this.montarCard(
								nome,
								lista.filter(item => item.cavalo && item.carreta).length,
								lista.filter(item => !item.cavalo || !item.carreta).length,
								lista.filter(item => {
									if (tipo == 1) {
										return item.vencido_revisao_tecnica || (item.laudoControleCarreta1 && item.laudoControleCarreta1.vencido_revisao_tecnica);
									} else if (tipo == 2) {
										return item.vencido_ruido;
									} else if (tipo == 3) {
										return item.vencido_opacidade;
									} else if (tipo == 4) {
										return item.vencido_tacografo;
									}
								}).length,
								lista.filter(item => item.ocorrencia || item.laudoControleCarreta1 && item.laudoControleCarreta1.ocorrencia).length);
						})}
												
					</Row>

					<ContentCard>
						<ContentCardHeader>
							<Row>
								<InputPesquisa
									cols='12 6 3 1'
									label='Pesquisar'
									minWidth={window.innerWidth > 725 ? 450 : window.innerWidth * 0.4}
									onChange={value => this.setState({ ...this.state, pesquisar: value })} />
								
								<div style={{ display: 'flex', justifyContent: 'left', flexWrap: 'wrap', width: 130, height: 78, marginTop: -2 }}>

									<div style={{ display: 'flex', alignItems: 'center', marginRight: 8  }} title='Classificadas'>
										<i class='fas fa-exclamation-triangle' style={{ marginRight: 4, width: 18 }}></i>										
										<div class="custom-control custom-switch">
											<input type="checkbox" class='custom-control-input' id='customSwitch1'
												checked={this.state.filtro.classificada}
												onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, classificada: !this.state.filtro.classificada }})} />
											<label class="custom-control-label" for="customSwitch1"></label>
										</div>
									</div>

									<div style={{ display: 'flex', alignItems: 'center' }} title='Vencidos'>
										<i class='fas fa-calendar-times' style={{ marginRight: 4, width: 18 }}></i>
										<div class="custom-control custom-switch">
											<input type="checkbox" class='custom-control-input' id='customSwitch2'
												checked={this.state.filtro.vencido}
												onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, vencido: !this.state.filtro.vencido }})} />
											<label class="custom-control-label" for="customSwitch2"></label>
										</div>
									</div>

									<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Ativos'>
										<i class='fas fa-check' style={{ marginRight: 4, width: 18 }}></i>
										<div class="custom-control custom-switch">
											<input type="checkbox" class='custom-control-input' id='customSwitch3'
												checked={this.state.filtro.ativo}
												onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, ativo: !this.state.filtro.ativo }})} />
											<label class="custom-control-label" for="customSwitch3"></label>
										</div>
									</div>

									<div style={{ display: 'flex', alignItems: 'center' }} title='Inativos'>
										<i class='fas fa-times' style={{ marginRight: 4, width: 18 }}></i>
										<div class="custom-control custom-switch">
											<input type="checkbox" class='custom-control-input' id='customSwitch4'
												checked={this.state.filtro.inativo}
												onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, inativo: !this.state.filtro.inativo }})} />
											<label class="custom-control-label" for="customSwitch4"></label>
										</div>
									</div>

									<div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }} title='Encostado, Acidentado ou Manutenção'>
										<i class='fas fa-stop-circle' style={{ marginRight: 4, width: 18 }}></i>
										<div class="custom-control custom-switch">
											<input type="checkbox" class='custom-control-input' id='customSwitch5'
												checked={this.state.filtro.encostado}
												onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, encostado: !this.state.filtro.encostado }})} />
											<label class="custom-control-label" for="customSwitch5"></label>
										</div>
									</div>

									<div style={{ display: 'flex', alignItems: 'center' }} title='Alerta de Revisão Técnica'>
										<i class='fas fa-exclamation' style={{ marginRight: 4, width: 18 }}></i>
										<div class="custom-control custom-switch">
											<input type="checkbox" class='custom-control-input' id='customSwitch6'
												checked={this.state.filtro.alertaRevisaoTecnica}
												onChange={data => this.setState({ ...this.state, filtro: { ...this.state.filtro, alertaRevisaoTecnica: !this.state.filtro.alertaRevisaoTecnica }})} />
											<label class="custom-control-label" for="customSwitch6"></label>
										</div>
									</div>
								</div>

								<div style={{ marginTop: 8 }}>
									<a class='btn btn-app bg-secondary' style={{ marginLeft: 2, minWidth: 75 }}
										onClick={() => {
											this.setState({ ...this.state, filtro: { ...this.state.filtro, frota: this.state.filtro.frota == 'normal' ? 'bitrem' : 'normal' }})
										}}>
										<i class={this.state.filtro.frota == 'normal' ? 'fas fa-truck' : 'fas fa-truck-moving'}></i> {this.state.filtro.frota == 'normal' ? 'F. Normal' : 'F. Bitrem'}
									</a>
									<a class='btn btn-app bg-primary' style={{ marginLeft: 2, minWidth: 75 }}
										onClick={() => {
											this.props.getLista();
										}}>
										<i class='fas fa-sync-alt'></i> Atualizar
									</a>
									<ExcelFile
										filename={'Laudos'}
										element={
											<a class='btn btn-app bg-success' style={{ marginLeft: 2, minWidth: 75 }}>
												<i class='fas fa-file-excel'></i> Exportar
											</a>
										}>
										{this.montarExcel(this.filtrar())}													
									</ExcelFile>
									<a class='btn btn-app bg-success' style={{ marginLeft: 2, minWidth: 75 }}
										onClick={() => {
											this.props.baixarAnexos(this.filtrar().filter(item => this.state.selecao_todas || this.state.selecao_lista.filter(selecao => selecao == item.id)[0]));
										}}>
										<i class='fas fa-file-download'></i> Baixar
									</a>
								</div>
							</Row>

						</ContentCardHeader>

						<ContentCardBody>
							<Table responsive border alturaFixa striped 
								maxHeight={window.innerWidth < 576 ? '100%' : (
									window.innerWidth < 768 ? window.innerHeight - 420 : (
										window.innerWidth < 992 ? window.innerHeight - 420 : window.innerHeight - 315
									)
								)}>
								<THead>
									<Tr backgroundColor={'#1F3066'} color={'#ffffff'}>
										<Th rowspan={2} alignCenter sticky width={10} color={'rgb(31, 48, 102, 0.9)'} small paddingTop={2} paddingBottom={2}>
											<i class={this.state.selecao_todas ? `far fa-check-square` : `far fa-square`} 
												style={{ fontSize: 16, marginLeft: 2, marginRight: 2 }}
												onClick={() => this.setState({ ...this.state, selecao_todas: !this.state.selecao_todas, selecao_lista: [] })}></i>
										</Th>
										<Th rowspan={2} alignCenter sticky width={80} color={'#1F3066'} small paddingTop={2} paddingBottom={2} 
											onClick={() => this.setState({ ...this.state, ordenacao: { cavalo: this.getNovaOrdenacao(this.state.ordenacao.cavalo) }})}>
											Cavalo {this.getOrdenacao(this.state.ordenacao.cavalo)}
										</Th>
										<Th rowspan={2} alignCenter sticky width={80} color={'#1F3066'} small paddingTop={2} paddingBottom={2} 
											onClick={() => this.setState({ ...this.state, ordenacao: { carreta1: this.getNovaOrdenacao(this.state.ordenacao.carreta1) }})}>
											Carreta1 {this.getOrdenacao(this.state.ordenacao.carreta1)}
										</Th>
										{this.state.filtro.frota == 'bitrem' &&
										<Th rowspan={2} alignCenter sticky width={80} color={'#1F3066'} small paddingTop={2} paddingBottom={2} 
											onClick={() => this.setState({ ...this.state, ordenacao: { carreta2: this.getNovaOrdenacao(this.state.ordenacao.carreta2) }})}>
											Carreta2 {this.getOrdenacao(this.state.ordenacao.carreta2)}
										</Th>}
										<Th rowspan={2} alignCenter sticky width={80} color={'#1F3066'} small paddingTop={2} paddingBottom={2} 
											onClick={() => this.setState({ ...this.state, ordenacao: { rumo: this.getNovaOrdenacao(this.state.ordenacao.rumo) }})}>
											Rumo {this.getOrdenacao(this.state.ordenacao.rumo)}
										</Th>
										<Th rowspan={2} alignCenter sticky width={90} color={'#1F3066'} small paddingTop={2} paddingBottom={2} 
											onClick={() => this.setState({ ...this.state, ordenacao: { nacional_internacional: this.getNovaOrdenacao(this.state.ordenacao.nacional_internacional) }})}>
											Nac/Int {this.getOrdenacao(this.state.ordenacao.nacional_internacional)}
										</Th>

										<Th rowspan={2} alignCenter sticky width={90} color={'#1F3066'} small paddingTop={2} paddingBottom={2} 
											onClick={() => this.setState({ ...this.state, ordenacao: { classificada: this.getNovaOrdenacao(this.state.ordenacao.classificada) }})}>
											<i class='fas fa-exclamation-triangle' title='Classificada'></i> {this.getOrdenacao(this.state.ordenacao.classificada)}
										</Th>

										<Th colspan={this.state.filtro.frota == 'bitrem' ? 10 : 8} alignCenter sticky width={1000} color={'#1F3066'} small paddingTop={2} paddingBottom={2} >
											Vencimento
										</Th>
										
										<Th rowspan={2} colspan={2} alignCenter sticky width={350} color={'#1F3066'} small paddingTop={2} paddingBottom={2} 
											onClick={() => this.setState({ ...this.state, ordenacao: { ocorrencia: this.getNovaOrdenacao(this.state.ordenacao.ocorrencia) }})}>
											Ocorrência {this.getOrdenacao(this.state.ordenacao.ocorrencia)}
										</Th>
										
										<Th rowspan={2} alignCenter sticky width={300} color={'#1F3066'} small paddingTop={2} paddingBottom={2} 
											onClick={() => this.setState({ ...this.state, ordenacao: { nome_fronteira: this.getNovaOrdenacao(this.state.ordenacao.nome_fronteira) }})}>
											Fronteira {this.getOrdenacao(this.state.ordenacao.nome_fronteira)}
										</Th>
										<Th rowspan={2} alignCenter sticky width={300} color={'#1F3066'} small paddingTop={2} paddingBottom={2} 
											onClick={() => this.setState({ ...this.state, ordenacao: { rota: this.getNovaOrdenacao(this.state.ordenacao.rota) }})}>
											Percurso {this.getOrdenacao(this.state.ordenacao.rota)}
										</Th>
										<Th rowspan={2} colspan={2} alignCenter sticky width={400} color={'#1F3066'} small paddingTop={2} paddingBottom={2} 
											onClick={() => this.setState({ ...this.state, ordenacao: { veiculo_referencia: this.getNovaOrdenacao(this.state.ordenacao.veiculo_referencia) }})}>
											Localização {this.getOrdenacao(this.state.ordenacao.veiculo_referencia)}
										</Th>
										<Th rowspan={2} alignCenter sticky minWidth={100} color={'#1F3066'} small paddingTop={2} paddingBottom={2} 
											onClick={() => this.setState({ ...this.state, ordenacao: { nome_status: this.getNovaOrdenacao(this.state.ordenacao.nome_status) }})}>
											Status {this.getOrdenacao(this.state.ordenacao.nome_status)}
										</Th>
									</Tr>
									<Tr backgroundColor={'#ffffff'} color={'#ffffff'} sticky top={24}>							
										<Th colspan={2} alignCenter sticky width={120} color={'#1F3066'} small paddingTop={1} paddingBottom={2} top={25}
											onClick={() => this.setState({ ...this.state, ordenacao: { data_vencimento_revisao_tecnica: this.getNovaOrdenacao(this.state.ordenacao.data_vencimento_revisao_tecnica) }})}>
											Rev.Técnica {this.getOrdenacao(this.state.ordenacao.data_vencimento_revisao_tecnica)}
										</Th>
										<Th colspan={2} alignCenter sticky width={100} color={'#1F3066'} small paddingTop={1} paddingBottom={2} top={25}
											onClick={() => this.setState({ ...this.state, ordenacao: { data_vencimento_ruido: this.getNovaOrdenacao(this.state.ordenacao.data_vencimento_ruido) }})}>
											Ruído {this.getOrdenacao(this.state.ordenacao.data_vencimento_ruido)}
										</Th>
										<Th colspan={2} alignCenter sticky width={100} color={'#1F3066'} small paddingTop={1} paddingBottom={2} top={25}
											onClick={() => this.setState({ ...this.state, ordenacao: { data_vencimento_opacidade: this.getNovaOrdenacao(this.state.ordenacao.data_vencimento_opacidade) }})}>
											Opacidade {this.getOrdenacao(this.state.ordenacao.data_vencimento_opacidade)}
										</Th>
										<Th colspan={2} alignCenter sticky width={120} color={'#1F3066'} small paddingTop={1} paddingBottom={2} top={25}
											onClick={() => this.setState({ ...this.state, ordenacao: { data_vencimento_revisao_tecnica_carreta1: this.getNovaOrdenacao(this.state.ordenacao.data_vencimento_revisao_tecnica_carreta1) }})}>
											Rev.Carreta 1 {this.getOrdenacao(this.state.ordenacao.data_vencimento_revisao_tecnica_carreta1)}
										</Th>
										{this.state.filtro.frota == 'bitrem' &&
										<Th colspan={2} alignCenter sticky width={120} color={'#1F3066'} small paddingTop={1} paddingBottom={2} top={25}
											onClick={() => this.setState({ ...this.state, ordenacao: { data_vencimento_revisao_tecnica_carreta2: this.getNovaOrdenacao(this.state.ordenacao.data_vencimento_revisao_tecnica_carreta2) }})}>
											Rev.Carreta 2 {this.getOrdenacao(this.state.ordenacao.data_vencimento_revisao_tecnica_carreta2)}
										</Th>}
										{/*<Th colspan={2} alignCenter sticky width={100} color={'#1F3066'} small paddingTop={1} paddingBottom={2} top={25}
											onClick={() => this.setState({ ...this.state, ordenacao: { data_vencimento_tacografo: this.getNovaOrdenacao(this.state.ordenacao.data_vencimento_tacografo) }})}>
											Tacógrafo {this.getOrdenacao(this.state.ordenacao.data_vencimento_tacografo)}
										</Th>*/}
									</Tr>
								</THead>
								<TBody>
									{this.filtrar().map(item => (
										<Tr key={item.id}>
											<Td alignCenter small color='rgb(31, 48, 102, 0.9)' title='Selecionar'>
												<i class={this.state.selecao_todas || this.state.selecao_lista.filter(selecao => selecao == item.id)[0] ? `far fa-check-square` : `far fa-square`} 
													style={{ fontSize: 16, marginLeft: 2, marginRight: 2 }}
													onClick={() => {
														let selecionado = this.state.selecao_lista.filter(selecao => selecao == item.id)[0];
														let listaSelecao = this.state.selecao_lista.filter(selecao => selecao != item.id);
														if (!selecionado) {
															listaSelecao.push(item.id);
														}
														this.setState({
															...this.state,
															selecao_todas: false,
															selecao_lista: listaSelecao
														});
													}}></i>
											</Td>
											<Td alignCenter small>{item.cavalo}</Td>
											<Td alignCenter small>{item.carreta1}</Td>
											{this.state.filtro.frota == 'bitrem' &&
											<Td alignCenter small>{item.carreta2}</Td>}
											<Td alignCenter small>{item.rumo}</Td>
											<Td alignCenter small>{item.rumo == 'NAC' ? 'NAC' : (item.rumo == 'RN' || item.rumo == 'RS' ? 'INT' : '-')}</Td>
											<Td alignCenter small color='#000' title='Classificada'>
												{item.classificada &&
												<i class='fas fa-exclamation-triangle' title='Classificada'></i>}
											</Td>
											
											<Td alignCenter small  width={100}
												backgroundColor={item.modo == 'cavalo' && item.vencido_revisao_tecnica ? '#de0025' : null}
												color={item.modo == 'cavalo' && item.vencido_revisao_tecnica ? '#ffffff' : null}
												bold={item.modo == 'cavalo' && item.vencido_revisao_tecnica}>{DateFormat.formatarDataSqlParaTela(item.modo == 'cavalo' ? item.data_vencimento_revisao_tecnica : null)}</Td>
											<Td alignCenter small width={10} title='Editar'>
												{item.modo == 'cavalo' &&
												<ButtonTable
													type={'primary'}
													icon={'fas fa-pencil-alt'}
													visible={true}
													small
													event={() => {
														this.setModalItem({
															...item,
															placa: item.cavalo
														}, 1);
													}} />}
											</Td>
											
											<Td alignCenter small  width={100}
												backgroundColor={item.modo == 'cavalo' && item.vencido_ruido ? '#de0025' : null}
												color={item.modo == 'cavalo' && item.vencido_ruido ? '#ffffff' : null}
												bold={item.modo == 'cavalo' && item.vencido_ruido}>{DateFormat.formatarDataSqlParaTela(item.modo == 'cavalo' ? item.data_vencimento_ruido : null)}</Td>
											<Td alignCenter small maxWidth={10} title='Editar'>
												{item.modo == 'cavalo' &&
												<ButtonTable
													type={'primary'}
													icon={'fas fa-pencil-alt'}
													visible={true}
													small
													event={() => {
														this.setModalItem({
															...item,
															placa: item.cavalo
														}, 2);
													}} />}
											</Td>
											
											<Td alignCenter small  width={100}
												backgroundColor={item.modo == 'cavalo' && item.vencido_opacidade ? '#de0025' : null}
												color={item.modo == 'cavalo' && item.vencido_opacidade ? '#ffffff' : null}
												bold={item.modo == 'cavalo' && item.vencido_opacidade}>{DateFormat.formatarDataSqlParaTela(item.modo == 'cavalo' ? item.data_vencimento_opacidade : null)}</Td>
											<Td alignCenter small width={10} title='Editar'>
												{item.modo == 'cavalo' &&
												<ButtonTable
													type={'primary'}
													icon={'fas fa-pencil-alt'}
													visible={true}
													small
													event={() => {
														this.setModalItem({
															...item,
															placa: item.cavalo
														}, 3);
													}} />}
											</Td>

											<Td alignCenter small  width={100}
												backgroundColor={item.laudoControleCarreta1 && item.laudoControleCarreta1.vencido_revisao_tecnica ? '#de0025' : null}
												color={item.laudoControleCarreta1 && item.laudoControleCarreta1.vencido_revisao_tecnica ? '#ffffff' : null}
												bold={item.laudoControleCarreta1 && item.laudoControleCarreta1.vencido_revisao_tecnica}>{DateFormat.formatarDataSqlParaTela(item.laudoControleCarreta1 ? item.laudoControleCarreta1.data_vencimento_revisao_tecnica : null)}</Td>
											<Td alignCenter small width={10} title='Editar'>
												{item.laudoControleCarreta1 &&
												<ButtonTable
													type={'primary'}
													icon={'fas fa-pencil-alt'}
													visible={true}
													small
													event={() => {
														this.setModalItem({
															...item.laudoControleCarreta1,
															placa: item.laudoControleCarreta1.carreta
														}, 1);
													}} />}
											</Td>
											
											{this.state.filtro.frota == 'bitrem' &&
											<Td alignCenter small  width={100}
												backgroundColor={item.laudoControleCarreta2 && item.laudoControleCarreta2.vencido_revisao_tecnica ? '#de0025' : null}
												color={item.laudoControleCarreta2 && item.laudoControleCarreta2.vencido_revisao_tecnica ? '#ffffff' : null}
												bold={item.laudoControleCarreta2 && item.laudoControleCarreta2.vencido_revisao_tecnica}>{DateFormat.formatarDataSqlParaTela(item.laudoControleCarreta2 ? item.laudoControleCarreta2.data_vencimento_revisao_tecnica : null)}</Td>}
											{this.state.filtro.frota == 'bitrem' &&
											<Td alignCenter small width={10} title='Editar'>
												{item.laudoControleCarreta2 &&
												<ButtonTable
													type={'primary'}
													icon={'fas fa-pencil-alt'}
													visible={true}
													small
													event={() => {
														this.setModalItem({
															...item.laudoControleCarreta2,
															placa: item.laudoControleCarreta2.carreta
														}, 1);
													}} />}
											</Td>}
											
											{/*<Td alignCenter small  width={100}
												backgroundColor={item.modo == 'cavalo' && item.vencido_tacografo ? '#de0025' : null}
												color={item.modo == 'cavalo' && item.vencido_tacografo ? '#ffffff' : null}
												bold={item.modo == 'cavalo' && item.vencido_tacografo}>{DateFormat.formatarDataSqlParaTela(item.data_vencimento_tacografo)}</Td>
											<Td alignCenter small width={10} title='Editar'>
												<ButtonTable
													type={'primary'}
													icon={'fas fa-pencil-alt'}
													visible={true}
													small
													event={() => {
														this.setModalItem(item, 4);
													}} />
											</Td>*/}

											{item.laudoControleCarreta1 && item.modo == 'carreta'  ? (
												<Td alignCenter small width={200}
													backgroundColor={item.laudoControleCarreta1.ocorrencia ? '#de0025' : null}
													color={item.laudoControleCarreta1.ocorrencia ? '#ffffff' : null}
													bold={item.laudoControleCarreta1.ocorrencia}>{item.laudoControleCarreta1.ocorrencia}</Td>
											) : (
												<Td alignCenter small width={200}
													backgroundColor={item.ocorrencia ? '#de0025' : null}
													color={item.ocorrencia ? '#ffffff' : null}
													bold={item.ocorrencia}>{item.ocorrencia}</Td>
											)}
											{item.laudoControleCarreta1 && item.modo == 'carreta' ? (
												<Td alignCenter small width={10} title='Editar'>
													<ButtonTable
														type={'primary'}
														icon={'fas fa-pencil-alt'}
														visible={true}
														small
														event={() => {
															this.setModalOcorrencia({
																...item.laudoControleCarreta1,
																placa: item.laudoControleCarreta1.carreta
															});
														}} />
												</Td>
											) : (
												<Td alignCenter small width={10} title='Editar'>
													<ButtonTable
														type={'primary'}
														icon={'fas fa-pencil-alt'}
														visible={true}
														small
														event={() => {
															this.setModalOcorrencia({
																...item,
																placa: item.cavalo
															});
														}} />
												</Td>
											)}

											<Td alignCenter small>{item.nome_fronteira}</Td>
											<Td alignCenter small>{item.rota} {(item.destino_internacional || item.esta_internacional) && <i class='fas fa-exclamation' style={{ color: item.alerta_vencimento ? '#e60000' : '#c79f00' }} />}</Td>
											<Td alignCenter small width={200}>{item.veiculo_referencia}</Td>
											<Td alignCenter small width={10} title='Mapa'>
												{(item.veiculo_latitude && item.veiculo_longitude) &&
												<ButtonTable
													type={'primary'}
													icon={'fas fa-eye'}
													visible={true}
													small
													event={() => {
														this.setModalCavalo(item);
													}} />}
											</Td>
											
											<Td alignCenter small>{item.nome_status}</Td>
										</Tr> 
									))}
								</TBody>
							</Table>
						</ContentCardBody>
					</ContentCard>
				</Content>
			</>
		);
	}

	setModalCavalo(controle) {
		this.setState({
			...this.state,
			modalCavaloControle: controle
		});
	}

	modalCavalo() {

		if (!this.state.modalCavaloControle) {
			return null;
		}

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header' style={{ backgroundColor: '#1F3066', color: '#fff' }}>
							<h4 class='modal-title'>{this.state.modalCavaloControle.cavalo}</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setModalCavalo(null)}>
								<span aria-hidden='true' style={{ color: '#fff', fontSize: 32 }}>×</span>
							</button>
						</div>
						<div class='modal-body' style={{ maxHeight: window.innerHeight * 0.8, overflowY: 'scroll' }}>
							<Grid cols="12 12 12 12">
								<div style={{ width: "100%", height: 600 }}>
									<MapLeaflet
										coordenadas={[{
												id: this.state.modalCavaloControle.id,
												titulo: `${this.state.modalCavaloControle.cavalo} - ${this.state.modalCavaloControle.veiculo_referencia}`,
												latitude: this.state.modalCavaloControle.veiculo_latitude,
												longitude: this.state.modalCavaloControle.veiculo_longitude,
												direcao: this.state.modalCavaloControle.rumo == 'RS' ? 'S' : 'N'
											}]}
										zoom={4}
										latitude={this.state.modalCavaloControle.veiculo_latitude}
										longitude={this.state.modalCavaloControle.veiculo_longitude}
										onChange={(coordenadas) => {

										}}
									/>
								</div>
							</Grid>

						</div>
					</div>
				</div>
			</div>
        );
    }

	setModalItem(controle, idTipo) {
		this.setState({
			...this.state,
			controle: controle,
			idTipoItem: idTipo
		});

		this.props.setModoTelaItem('lista');
	}

	modalItem() {

		if (!this.state.controle) {
			return null;
		}

		let nomeItem = '';
		if (this.state.idTipoItem == 1) {
			nomeItem = 'REVISÃO TÉCNICA';
		} else if (this.state.idTipoItem == 2) {
			nomeItem = 'RUÍDO';
		} else if (this.state.idTipoItem == 3) {
			nomeItem = 'OPACIDADE';
		} else if (this.state.idTipoItem == 4) {
			nomeItem = 'TACÓGRAFO';
		}

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>{this.state.controle.placa} - {nomeItem}</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setModalItem(null)}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body'>
							<LaudoControleItem id_controle={this.state.controle.id} id_tipo={this.state.idTipoItem} />
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => this.setModalItem(null)}>Fechar</button>
						</div>
					</div>
				</div>
			</div>
        );
    }

	setModalOcorrencia(controle) {
		this.setState({
			...this.state,
			controleOcorrencia: controle
		});

		this.props.setModoTelaOcorrencia('lista');
	}

	modalOcorrencia() {

		if (!this.state.controleOcorrencia) {
			return null;
		}

        return (
			<div class='modal fade show' id='modal-xl' style={{ display: 'block' }} aria-modal='true'>
				<div class='modal-dialog modal-xl'>
					<div class='modal-content'>
						<div class='modal-header'>
							<h4 class='modal-title'>{this.state.controleOcorrencia.placa} - Ocorrências</h4>
							<button type='button' class='close' data-dismiss='modal' aria-label='Close' onClick={() => this.setModalOcorrencia(null)}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div class='modal-body'>
							<LaudoOcorrencia id_controle={this.state.controleOcorrencia.id} />
						</div>
						<div class='modal-footer justify-content-between'>
							<button type='button' class='btn btn-default' data-dismiss='modal' style={{ visibility: 'hidden' }}>Close</button>
							<button type='button' class='btn btn-primary'
								onClick={() => this.setModalOcorrencia(null)}>Fechar</button>
						</div>
					</div>
				</div>
			</div>
        );
    }

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.laudoControle.modoTela,
	filtro: state.laudoControle.filtro,
	aguardando: state.laudoControle.aguardando,
	lista: state.laudoControle.lista,
	listaTipo: state.laudoControle.listaTipo,
	listaEvento: state.laudoControle.listaEvento,
	listaUnidade: state.laudoControle.listaUnidade,
	listaVeiculo: state.laudoControle.listaVeiculo
});
const mapDispatchToProps = dispatch => bindActionCreators({ 
	setFiltro, getLista, getListaTipo, getListaUnidade, getListaVeiculo, getListaEvento,
	setModoTelaItem, setModoTelaOcorrencia, baixarAnexos
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(LaudoControle);

import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import UsuarioPerfilReducer from '../usuarioPerfil/usuarioPerfilReducer';
import GrupoReducer from '../grupo/grupoReducer';
import MotoristaReducer from '../motorista/motoristaReducer';
import PremioFaixaReducer from '../premioFaixa/premioFaixaReducer';
import OcorrenciaTipoReducer from '../ocorrenciaTipo/ocorrenciaTipoReducer';
import OcorrenciaReducer from '../ocorrencia/ocorrenciaReducer';
import OcorrenciaAcaoReducer from '../ocorrenciaAcao/ocorrenciaAcaoReducer';
import OcorrenciaStatusReducer from '../ocorrenciaStatus/ocorrenciaStatusReducer';
import PremiacaoReducer from '../premiacao/premiacaoReducer';
import DocumentoReducer from '../documento/documentoReducer';
import OperacaoReducer from '../operacao/operacaoReducer';
import OperacaoPerfilReducer from '../operacaoPerfil/operacaoPerfilReducer';
import DocumentoViagemReducer from '../documentoViagem/documentoViagemReducer';
import UnidadeReducer from '../unidade/unidadeReducer';
import VeiculoReducer from '../veiculo/veiculoReducer';
import UsuarioAcessorioReducer from '../usuarioAcessorio/usuarioAcessorioReducer';
import AcessorioTipoReducer from '../acessorioTipo/acessorioTipoReducer';
import AcessorioEventoReducer from '../acessorioEvento/acessorioEventoReducer';
import AcessorioSaidaReducer from '../acessorioSaida/acessorioSaidaReducer';
import AcessorioEntradaReducer from '../acessorioEntrada/acessorioEntradaReducer';
import AcessorioTransferenciaReducer from '../acessorioTransferencia/acessorioTransferenciaReducer';
import AcessorioInventarioReducer from '../acessorioInventario/acessorioInventarioReducer';
import ValePedagioReducer from '../valePedagio/valePedagioReducer';
import ValePedagioMotivoReducer from '../valePedagioMotivo/valePedagioMotivoReducer';
import ValePedagioPeriodoReducer from '../valePedagioPeriodo/valePedagioPeriodoReducer';
import ValePedagioMotoristaReducer from '../valePedagioMotorista/valePedagioMotoristaReducer';
import AcessorioSaldoReducer from '../acessorioSaldo/acessorioSaldoReducer';
import AcessorioHistoricoReducer from '../acessorioHistorico/acessorioHistoricoReducer';
import DespesaTipoReducer from '../despesaTipo/despesaTipoReducer';
import DespesaReducer from '../despesa/despesaReducer';
import ReportsReducer from '../reports/reportsReducer';
import ReportsHistoricoReducer from '../reportsHistorico/reportsHistoricoReducer';
import ReportsConformidadeReducer from '../reportsConformidade/reportsConformidadeReducer';
import ReportsIntegracaoGMReducer from '../reportsIntegracaoGM/reportsIntegracaoGMReducer';
import ReportsIntegracaoTetraPakReducer from '../reportsIntegracaoTetraPak/reportsIntegracaoTetraPakReducer';
import ReportsTrackReducer from '../reportsTrack/reportsTrackReducer';
import OperacaoDespesaReducer from '../operacaoDespesa/operacaoDespesaReducer';
import ComissaoTipoReducer from '../comissaoTipo/comissaoTipoReducer';
import ComissaoOperacaoReducer from '../comissaoOperacao/comissaoOperacaoReducer';
import ComissaoApuracaoReducer from '../comissaoApuracao/comissaoApuracaoReducer';
import ComprovanteEntregaReducer from '../comprovanteEntrega/comprovanteEntregaReducer';
import BraskemReducer from '../braskem/braskemReducer';
import LaudoControleReducer from '../laudoControle/laudoControleReducer';
import LaudoControleItemReducer from '../laudoControleItem/laudoControleItemReducer';
import LaudoOcorrenciaTipoReducer from '../laudoOcorrenciaTipo/laudoOcorrenciaTipoReducer';
import LaudoOcorrenciaReducer from '../laudoOcorrencia/laudoOcorrenciaReducer';
import ReportsIntegracaoVolvoReducer from '../reportsIntegracaoVolvo/reportsIntegracaoVolvoReducer';
import EtiquetaReducer from '../etiqueta/etiquetaReducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
    dashboard: DashboardReducer,
    usuario: UsuarioReducer,
	usuarioPerfil: UsuarioPerfilReducer,
	grupo: GrupoReducer,
	motorista: MotoristaReducer,
	premioFaixa: PremioFaixaReducer,
	ocorrenciaTipo: OcorrenciaTipoReducer,
	ocorrencia: OcorrenciaReducer,
	ocorrenciaAcao: OcorrenciaAcaoReducer,
	ocorrenciaStatus: OcorrenciaStatusReducer,
	premiacao: PremiacaoReducer,
	documento: DocumentoReducer,
	operacao: OperacaoReducer,
	operacaoPerfil: OperacaoPerfilReducer,
	operacaoDespesa: OperacaoDespesaReducer,
	documentoViagem: DocumentoViagemReducer,
	unidade: UnidadeReducer,
	veiculo: VeiculoReducer,
	usuarioAcessorio: UsuarioAcessorioReducer,
	acessorioTipo: AcessorioTipoReducer,
	acessorioEvento: AcessorioEventoReducer,
	acessorioSaida: AcessorioSaidaReducer,
	acessorioEntrada: AcessorioEntradaReducer,
	acessorioTransferencia: AcessorioTransferenciaReducer,
	acessorioInventario: AcessorioInventarioReducer,
	valePedagio: ValePedagioReducer,
	valePedagioMotivo: ValePedagioMotivoReducer,
	valePedagioPeriodo: ValePedagioPeriodoReducer,
	valePedagioMotorista: ValePedagioMotoristaReducer,
	acessorioSaldo: AcessorioSaldoReducer,
	acessorioHistorico: AcessorioHistoricoReducer,
	despesaTipo: DespesaTipoReducer,
	despesa: DespesaReducer,
	reports: ReportsReducer,
	reportsHistorico: ReportsHistoricoReducer,
	reportsConformidade: ReportsConformidadeReducer,
	reportsIntegracaoGM: ReportsIntegracaoGMReducer,
	reportsIntegracaoTetraPak: ReportsIntegracaoTetraPakReducer,
	reportsTrack: ReportsTrackReducer,
	comissaoTipo: ComissaoTipoReducer,
	comissaoOperacao: ComissaoOperacaoReducer,
	comissaoApuracao: ComissaoApuracaoReducer,
	comprovanteEntrega: ComprovanteEntregaReducer,
	braskem: BraskemReducer,
	laudoControle: LaudoControleReducer,
	laudoControleItem: LaudoControleItemReducer,
	laudoOcorrenciaTipo: LaudoOcorrenciaTipoReducer,
	laudoOcorrencia: LaudoOcorrenciaReducer,
	reportsIntegracaoVolvo: ReportsIntegracaoVolvoReducer,
	etiqueta: EtiquetaReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;

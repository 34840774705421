import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndTextarea from '../common/form/labelAndTextarea';
import Select from '../common/form/select';

import { gerar } from './etiquetaActions';
import Aguardando from '../common/template/aguardando';
import ContentCardFooter from '../common/template/contentCardFooter';

class Etiqueta extends Component {

	state = {
		linhaSelecionada: null
	}

    componentWillMount() {
    }

    render() {
        return (
            <div>
				<Aguardando aguardando={this.props.aguardando} />

                <Content>
					<ContentCard>
						<ContentCardHeader>
							
						</ContentCardHeader>
						<ContentCardBody>

							<Row alignCenter>
								<Select
									name='id_endereco_destino'
									options={this.props.listaEnderecoDestino}
									label='Endereço de Destino'
									cols='4 4 4 4'
									placeholder='Selecione'
									value={this.state.id_endereco_destino}
									onChange={value => {

										const endereco = this.props.listaEnderecoDestino.filter(item => item.id == value)[0];

										this.setState({
											...this.state,
											id_endereco_destino: value,
											descricao_endereco_destino: endereco ? endereco.descricao : ''
										});
									}} />

								<LabelAndTextarea
									name='endereco_fornecedor'
									label='Endereço do Fornecedor'
									cols='4 4 4 4'
									placeholder='Informe'
									value={this.state.endereco_fornecedor}
									onChange={data => {
										this.setState({
											...this.state,
											endereco_fornecedor: data.target.value
										});
									}} />
								
							</Row>

							<Row alignCenter>
								<LabelAndInput
									name='pedido_compra'
									label='Pedido de Compra'
									cols='3 3 3 3'
									placeholder='Informe'
									value={this.state.pedido_compra}
									onChange={data => {
										this.setState({
											...this.state,
											pedido_compra: data.target.value
										});
									}} />

								<LabelAndInput
									name='nota_fiscal'
									label='Nota Fiscal'
									cols='5 5 5 5'
									placeholder='Informe'
									value={this.state.nota_fiscal}
									onChange={data => {
										this.setState({
											...this.state,
											nota_fiscal: data.target.value
										});
									}} />
								
							</Row>

							<Row alignCenter>
								<LabelAndInputNumber
									name='numero_caixa_inicio'
									label='Número de Caixas - Início'
									cols='2 2 2 2'
									placeholder='Informe'
									casas={0}
									value={this.state.numero_caixa_inicio}
									onChange={data => {
										this.setState({
											...this.state,
											numero_caixa_inicio: data.target.value
										});
									}} />

								<LabelAndInputNumber
									name='numero_caixa_fim'
									label='Número de Caixas - Fim'
									cols='2 2 2 2'
									placeholder='Informe'
									casas={0}
									value={this.state.numero_caixa_fim}
									onChange={data => {
										this.setState({
											...this.state,
											numero_caixa_fim: data.target.value
										});
									}} />

								<LabelAndInputNumber
									name='numero_caixa_total'
									label='Número de Caixas - Total'
									cols='4 4 4 4'
									placeholder='Informe'
									casas={0}
									value={this.state.numero_caixa_total}
									onChange={data => {
										this.setState({
											...this.state,
											numero_caixa_total: data.target.value
										});
									}} />
								
							</Row>
						</ContentCardBody>
						<ContentCardFooter>
							<Row alignCenter>
								<Grid cols='6 6 4 2'>
									<Button
										text='Gerar'
										type={'success'}
										icon={'fa fa-plus'}
										event={() => {
											this.props.gerar({
												...this.state
											});
										}} />									
									</Grid>
								<Grid cols='6 6 4 2'>										
									<Button
										text='Limpar'
										type={'warning'}
										icon={'fa fa-plus'}
										event={() => {
											this.setState({
												id_endereco_destino: '',
												descricao_endereco_destino: '',
												endereco_fornecedor: '',
												pedido_compra: '',
												nota_fiscal: '',
												numero_caixa_inicio: '',
												numero_caixa_fim: '',
												numero_caixa_total: ''
											});
										}} />
								</Grid>
							</Row>
						</ContentCardFooter>
					</ContentCard>
                </Content>
            </div>
        )
    }


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	aguardando: state.etiqueta.aguardando,
	listaEnderecoDestino: state.etiqueta.listaEnderecoDestino
});
const mapDispatchToProps = dispatch => bindActionCreators({ gerar }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Etiqueta);
